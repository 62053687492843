export enum FactBoxItemTypes {
  DEFAULT,
  LIST,
}

export interface FactBoxItemProps {
  label: string | string[]
  value?: any
  labelClass?: string
  valueClass?: string
  isWeighted?: boolean
  showTooltip?: boolean
  tooltipId?: string
  tooltipContent?: HTMLElement | string
  variant?: FactBoxItemTypes
  dataTestid?: string
}

export type classType = string | ((data: any, columnDef: string) => string)
export interface FactBoxHeader {
  label: string
  columnDef: string
  classes?: classType
  columnClasses?: string
  formater?: (data: any, columnDef: string) => string
  onShowTooltip?: (data: any) => { id: string; content: HTMLElement }
}
